import React, {useEffect, useState} from "react";

const EditSpecialization = props =>  {

    const {item, callbackDelete, callbackEdit} = props;

    const [id, setId] = useState(item._id);
    const [label, setLabel] = useState(item.label);
    const [priceId, setPriceId] = useState(item.priceId || '');
    const [priceValue, setPriceValue] = useState(item.priceValue || '');
    const [priceCurrency, setPriceCurrency] = useState(item.priceCurrency || '');

    useEffect(() => {
        setId(item._id);
        setLabel(item.label);
        setPriceCurrency(item.priceCurrency)
        setPriceValue(item.priceValue)
        setPriceId(item.priceId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);

    const sendDeleteCallback = () => {
        callbackDelete(id)
    }

    const sendEditCallback = () => {
        callbackEdit(id, {
            label: label.toLowerCase(),
            type: "primary",
            ...(priceId && { priceId }),
            ...(priceValue && { priceValue }),
            ...(priceCurrency && { priceCurrency }),
        })
    }

    return (
        <div>
            <div className="d-flex-wrap-ac wp-100 mt-20">
                <div className="w-200 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Специализация:</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={label} onChange={(e) => setLabel(e.target.value)}/>
                    </div>
                </div>
                <div className="w-300 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>priceID (stripe):</strong></p>
                    <div className="mt-5">
                        <input className="wp-100" value={priceId} onChange={(e) => setPriceId(e.target.value)}/>
                    </div>
                </div>
                <div className="w-100 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Цена:</strong></p>
                    <div className="mt-5">
                        <input type={"number"} min={1} max={999} className="wp-100" value={priceValue} onChange={(e) => setPriceValue(e.target.value)}/>
                    </div>
                </div>
                <div className="w-100 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>Валюта:</strong></p>
                    <div className="mt-5">
                        <input type={"text"} min={1} max={999} className="wp-100" value={priceCurrency} onChange={(e) => setPriceCurrency(e.target.value)}/>
                    </div>
                </div>
                <div className="w-175 mb-20 mr-15 wmobile">
                    <p className="fs-12"><strong>id:</strong></p>
                    <div className="mt-5">
                        <p className='fs-10'>{id}</p>
                    </div>
                </div>

                <div className="mb-20">
                    <p className="fs-12"><strong>Действия:</strong></p>
                    <div className="mt-5">
                        <button className="button-link" onClick={() => window.confirm("Вы точно хотите удалить эту специализацию?") &&
                            sendDeleteCallback()
                        }>Удалить</button>
                        <button onClick={() => window.confirm("Вы точно хотите обновить эту специализацию?") &&
                            sendEditCallback()
                        }>Обновить</button>
                    </div>
                </div>
            </div>
            <div className="separator wp-100" />
        </div>
    );
}

export default EditSpecialization;
